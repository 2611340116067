import { useMemo } from 'react';
import { useMedias } from '@divlab/divanui';

import { useRequest } from '@Contexts/Request';
import chunkArray from '@Utils/chunkArray';
import GallerySection from '@Components/GallerySection';
import PopularSlider from './elements/PopularSlider';

import type { PopularCategoryData } from './typings';
import type { FC, HTMLAttributes } from 'react';

interface PopularProps extends HTMLAttributes<HTMLDivElement> {
  categories: PopularCategoryData[];
  title?: string;
  view?: 'trends';
}

type Slide = {
  categories: PopularCategoryData[];
  id: number;
};

const Popular: FC<PopularProps> = (props) => {
  const { title, categories = [], view, ...restProps } = props;
  const { isDesktop, isDesktopM, isMobile } = useMedias();
  const { isBlr } = useRequest();

  // В зависимости от ширины экрана получаем количество отображаемых слайдов
  const countCardsInSlide = useMemo(() => {
    if (isMobile) return 1;
    if (isDesktopM && view === 'trends') return 2;
    if (isDesktop) return 2;

    return 6;
  }, [isMobile, isDesktopM, isDesktop, view]);

  const slides: Slide[] = useMemo(() => {
    const array = chunkArray(categories, countCardsInSlide);

    return array.map((slide, index) => ({ id: index + 1, categories: slide }));
  }, [categories, countCardsInSlide]);

  return (
    <GallerySection
      fullWidth
      defaultGap
      title={title}
      slides={slides}
      pagination
      scrollbar={false}
      titleTag={isBlr ? 'div' : 'h2'}
      renderSlide={(slide) => (
        <PopularSlider count={countCardsInSlide} categories={slide.categories} view={view} />
      )}
      {...restProps}
    />
  );
};

export default Popular;
