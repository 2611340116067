import { cn, Discount } from '@divlab/divanui';

import Link from '@Navigation/Link';
import Img from '@UI/Img';
import CardInView from '@Components/CardInView';
import styles from './Slide.module.css';

import type { FC, HTMLAttributes } from 'react';
import type { CategoriesInStockData } from '@Pages/PageIndex/typings';

interface CardProps extends HTMLAttributes<HTMLDivElement> {
  slide: CategoriesInStockData[];
}

const Slide: FC<CardProps> = (props) => {
  const { className, slide, ...restProps } = props;

  return (
    <div className={cn(styles.slide, className)} {...restProps}>
      {slide.map((card) => (
        <Link
          className={cn(styles.card, { [styles.oneCard]: slide.length === 1 })}
          key={card.name}
          to={card.url}
        >
          <CardInView
            id={card.name}
            listTitle='mainSale_slider'
            card={{ link: card.url, title: card.name }}
            cardType='promo'
            targetPathname={card.url}
          >
            <div className={styles.textContainer}>
              <div className={styles.name}>{card.name}</div>
              <Discount
                className={styles.sticker}
                value={card.sale}
                customText
                override={{ backgroundColor: '#FFE1BE', textColor: '#000' }}
              />
            </div>
            <div className={styles.imgContainer}>
              <Img src={card.image} className={styles.img} />
            </div>
          </CardInView>
        </Link>
      ))}
    </div>
  );
};

export default Slide;
