import { memo, useEffect } from 'react';
import { Gallery } from '@divlab/divanui';
import { useInView } from 'react-intersection-observer';

import CategoryCard from '@Components/CategoryCard';
import CardInView from '@Components/CardInView';
import { useDeps } from '@Contexts/DI';
import styles from './Categories.module.css';

import type { FC, HTMLAttributes } from 'react';
import type { CategoryData } from '@Pages/PageIndex/typings';

interface CategoriesProps extends HTMLAttributes<HTMLDivElement> {
  categories: CategoryData[];
}

const listTitle = 'undermain_slider';

const Categories: FC<CategoriesProps> = (props) => {
  const { categories, ...restProps } = props;
  const { ref, inView } = useInView();
  const { analytics } = useDeps();

  useEffect(() => {
    if (!inView) return;

    setTimeout(() => {
      analytics.dispatchEvent('categories.view', { listTitle, label: 'Категория блок' });
    });
  }, [inView, analytics]);

  return (
    <div {...restProps} ref={ref}>
      <Gallery className={styles.gallery} cnSlide={styles.slide} scrollbar>
        {categories.map((category, index) => (
          <CardInView
            key={category.id}
            listTitle={listTitle}
            card={category}
            cardType='category'
            id={category.id.toString()}
            position={index}
          >
            <CategoryCard category={category} />
          </CardInView>
        ))}
      </Gallery>
    </div>
  );
};

export default memo(Categories);
