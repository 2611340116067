import { memo, useCallback, useState } from 'react';
import { cn, Gallery, useMedias } from '@divlab/divanui';

import { useDevPanel } from '@Stores/DevPanel';
import NavSideArrows from '@Components/NavSideArrows';
import CardInView from '@Components/CardInView';
import AdvertisingMarker from '@Promo/elems/AdvertisingMarker';
import SmartRender from '@Components/SmartRender';
import DefaultTemplate from './templates/DefaultTemplate';
import FRKTemplate from './templates/FRK/FRKTemplate';
import SwitchThemeTemplate from './templates/SwitchThemeTemplate';
import ATRTemplate from './templates/ATR/ATRTemplate';
import TextWithLinksTemplate from './templates/TextWithLinksTemplate';
import YandexTemplate from './templates/Yandex/YandexTemplate';
import PromocodeTemplate from './templates/PromocodeTemplate';
import PromocodeNewTemplate from './templates/PromocodeNewTemplate';
import styles from './MainGallery.module.css';

import type { FC } from 'react';
import type { SwiperClass } from '@divlab/divanui';
import type { MainGalleryProps, SlideTemplateProps } from './typings';

const SlideTemplate: FC<SlideTemplateProps> = (props) => {
  switch (props.banner?.template) {
    case 'default':
    default:
      return <DefaultTemplate {...props} />;
    case 'TextWithLinks':
      return <TextWithLinksTemplate {...props} />;
    case 'promocode':
      return <PromocodeTemplate {...props} />;
    case 'promocodeNew':
      return <PromocodeNewTemplate {...props} />;
    case 'FRK':
      return <FRKTemplate {...props} />;
    case 'ATR':
      return <ATRTemplate {...props} />;
    case 'SwitchTheme':
      return <SwitchThemeTemplate {...props} />;
    case 'Yandex':
      return <YandexTemplate {...props} />;
  }
};

const MainGallery: FC<MainGalleryProps> = (props) => {
  const { className, promotions, ...restProps } = props;
  const { currentDate: devCurrentDate } = useDevPanel();
  const currentDate = devCurrentDate || new Date();
  const { isDesktop } = useMedias();
  const [slideIndex, setSlideIndex] = useState(0);

  const handleSlideChange = (swiper: SwiperClass) => setSlideIndex(swiper.realIndex);

  const renderButtons = useCallback(
    ({ onPrev, onNext }) => {
      if (isDesktop) return null;

      const banner = promotions[slideIndex]?.materials[0];

      if (!banner) return null;
      if (promotions.length < 2) return null;

      return (
        <NavSideArrows
          view='chevron'
          inversed={banner.inversed}
          onPrev={onPrev}
          onNext={onNext}
          cnArrows={styles.arrows}
        />
      );
    },
    [promotions, slideIndex, isDesktop],
  );

  return (
    <div className={cn(styles.wrapper, className)} {...restProps}>
      {promotions?.length > 0 && (
        <Gallery
          className={styles.gallery}
          slidesPerView={1}
          initialSlideIndex={slideIndex}
          onSlideChange={handleSlideChange}
          renderButtons={renderButtons}
          loop
          pagination
          autoplay={{ delay: 6000 }}
        >
          {promotions.map((promotion, index) => {
            const banner = promotion.materials[0];

            return (
              <CardInView
                listTitle='main-slider'
                position={index}
                card={banner}
                cardType='promo'
                isSingleCard
                id={banner.id}
                targetPathname={banner.link}
                key={promotion.name}
                data-testid={`main-banner-slide${index + 1}`}
              >
                <SlideTemplate
                  key={banner.id}
                  promotion={promotion}
                  banner={banner}
                  currentDate={currentDate}
                  lazy={index !== 0}
                />
                <SmartRender countries={['RUS']}>
                  {banner.text?.advertiser && (
                    <AdvertisingMarker
                      className={styles.infoPopup}
                      advertiserName={banner.text.advertiser}
                    />
                  )}
                </SmartRender>
              </CardInView>
            );
          })}
        </Gallery>
      )}
    </div>
  );
};

const MainGalleryContainer: FC<MainGalleryProps> = (props) => {
  const { className, promotions, ...restProps } = props;

  return promotions?.length > 0 ? (
    <MainGallery {...props} />
  ) : (
    <div className={cn(styles.placeholder, className)} {...restProps} />
  );
};

export default memo(MainGalleryContainer);
