import { useState, memo, useCallback } from 'react';
import { cn } from '@divlab/divanui';

import useMedias from '@Hooks/useMedias';
import Link from '@Navigation/Link';
import Video from '@Promo/elems/Video';
import Img from '@UI/Img';
import Timer from '@Promo/elems/Timer';
import Sound from '@Promo/elems/Sound';
import Text from '@Promo/elems/Text';
import Switch from '@Promo/elems/Switch';
import styles from './SwitchThemeTemplate.module.css';

import type { FC, MouseEvent } from 'react';
import type { SlideTemplateProps } from '../../typings';

const SwitchThemeTemplate: FC<SlideTemplateProps> = (props) => {
  const { promotion, banner, currentDate, lazy, ...restProps } = props;
  const { isMobile } = useMedias();
  const [muted, setMuted] = useState(true);
  const [theme, setTheme] = useState<'light' | 'dark'>('dark');

  const needTimer = banner.timerStartDate ? new Date(banner.timerStartDate) < currentDate : null;

  const resources = needTimer ? banner.resources.timer : banner.resources.default;

  const toggleMute = useCallback((event: MouseEvent) => {
    //для предотвращения клика по ссылке, если иконка находится поверх ссылки
    event.preventDefault();
    event.stopPropagation();
    setMuted((prev) => !prev);
  }, []);

  const isVideo = !!resources.desktopVideo;
  const alignmentX = banner.alignmentX;
  const alignmentY = needTimer ? 'top' : banner.alignmentY;
  const text = banner.text || {};

  const dark = theme === 'dark';

  const toggleTheme = useCallback((event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    setTheme((prevTheme) => (prevTheme === 'light' ? 'dark' : 'light'));
  }, []);

  const desktopImage = dark ? resources.desktopImageDark : resources.desktopImage;
  const mobileImage = dark ? resources.mobileImageDark : resources.mobileImage;

  return (
    <Link
      {...restProps}
      className={cn(styles.banner, { [styles.inversed]: dark })}
      to={banner.link}
    >
      {isVideo ? (
        <Video
          className={styles.video}
          src={isMobile ? resources.mobileVideo : resources.desktopVideo}
          poster={isMobile ? mobileImage : desktopImage}
          muted={muted}
          playsInline
          autoPlay
          loop
        />
      ) : (
        <Img
          className={styles.image}
          src={isMobile ? mobileImage : desktopImage}
          loading={lazy ? 'lazy' : 'eager'}
        />
      )}

      <div
        className={cn(styles.content, {
          [styles.centerCenter]: alignmentX === 'center' && alignmentY === 'center',
          [styles.centerTop]: alignmentX === 'center' && alignmentY === 'top',
          [styles.leftCenter]: alignmentX === 'left' && alignmentY === 'center',
          [styles.leftTop]: alignmentX === 'left' && alignmentY === 'top',
        })}
      >
        {text.period && (
          <Text
            className={styles.period}
            desktop={text.period}
            mobile={text.periodMobile}
            isMobile={isMobile}
          />
        )}

        <Text
          className={styles.title}
          desktop={dark ? text.darkTheme.title : text.title}
          mobile={dark ? text.darkTheme.titleMobile : text.titleMobile}
          isMobile={isMobile}
        />

        {text.subtitle && (
          <Text
            className={styles.subtitle}
            desktop={text.subtitle}
            mobile={text.subtitleMobile}
            isMobile={isMobile}
          />
        )}

        <Text
          className={cn(styles.link, { [styles.withSound]: banner.needSound })}
          desktop={text.link}
        />

        <Switch onClick={toggleTheme} theme={theme} />
        {banner.needSound && <Sound className={styles.sound} muted={muted} onClick={toggleMute} />}

        {needTimer && (
          <Timer
            className={styles.timer}
            endDate={new Date(promotion.endDate)}
            textBeforeTimer={text.timer}
            textAfterTimer={text.afterTimer}
          />
        )}

        {text.condition && (
          <Text
            className={styles.condition}
            desktop={text.condition}
            mobile={text.conditionMobile}
            isMobile={isMobile}
          />
        )}
      </div>
    </Link>
  );
};

export default memo(SwitchThemeTemplate);
