import { useState } from 'react';
import { cn } from '@divlab/divanui';

import { useDeps } from '@Contexts/DI';
import useTranslation from '@Queries/useTranslation';
import Tooltip from './Tooltip';
import CopyIcon from './CopyIcon';
import CopiedIcon from './CopiedIcon';
import styles from './Promocode.module.css';

import type { FC, HTMLAttributes } from 'react';

interface PromocodeProps extends HTMLAttributes<HTMLDivElement> {
  text: string;
  view?: 'dark' | 'light';
  delayTooltip?: number;
}

const Promocode: FC<PromocodeProps> = (props) => {
  const { text, view = 'light', delayTooltip = 3000, className, ...restProps } = props;
  const { analytics } = useDeps();
  const { t } = useTranslation();
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    if (navigator?.clipboard && !copied) {
      navigator.clipboard.writeText(text);
      setCopied(true);

      analytics.dispatchEvent('landing.interact', {
        label: '',
        category: 'promocode',
        action: 'click',
      });

      const timer = setTimeout(() => setCopied(false), delayTooltip);

      return () => clearTimeout(timer);
    }
  };

  return (
    <div
      {...restProps}
      className={cn(
        styles.wrapper,
        {
          [styles.light]: view === 'light',
          [styles.dark]: view === 'dark',
          [styles.copied]: copied,
        },
        className,
      )}
    >
      <div className={styles.content} onClick={handleCopy}>
        <p className={styles.text}>{text}</p>
        {copied ? (
          <CopiedIcon className={cn(styles.icon, styles.iconCopied)} />
        ) : (
          <CopyIcon className={cn(styles.icon, styles.iconCopy)} />
        )}
      </div>

      <Tooltip visible={copied} timerMs={delayTooltip}>
        {t('ui.promocode.copied')}
      </Tooltip>
    </div>
  );
};

export default Promocode;
