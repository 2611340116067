import { memo, useMemo } from 'react';

import { useRequest } from '@Contexts/Request';
import useTranslation from '@Queries/useTranslation';
import GallerySection from '@Components/GallerySection';
import PromoCard from '@Components/PromoCard';
import styles from './Recommendations.module.css';

import type { FC, HTMLAttributes } from 'react';
import type { Promotion, CardBannerData } from '@Promo/typings';

interface RecommendationsProps extends HTMLAttributes<HTMLDivElement> {
  promotions?: Promotion<CardBannerData>[];
}

const Recommendations: FC<RecommendationsProps> = (props) => {
  const { promotions, ...restProps } = props;
  const { t } = useTranslation();
  const { isBlr } = useRequest();

  const cards = useMemo(() => {
    return promotions.reduce<CardBannerData[]>((prev, current) => {
      // По требованию SEO добавляем ко всем изображениям на главной странице на divan.by атрибуты alt
      const newCard = current.materials.map((material) => {
        return {
          ...material,
          resources: {
            ...material.resources,
            default: {
              ...material.resources.default,
              alt: isBlr ? material.text.title : null,
            },
          },
        };
      });
      return [...prev, ...newCard];
    }, []);
  }, [promotions, isBlr]);

  return (
    <GallerySection
      className={styles.wrapper}
      cnGallery={styles.gallery}
      cnSlide={styles.slide}
      flexible
      defaultGap
      title={t('recommendations')}
      titleTag={isBlr ? 'div' : 'h2'}
      slides={cards}
      renderSlide={(card) => <PromoCard card={card} />}
      analytics={(card, index) => ({
        id: card.id,
        card,
        listTitle: 'Рекомендуем сегодня',
        cardType: 'promo',
        targetPathname: card.link,
        position: index,
      })}
      {...restProps}
    />
  );
};

export default memo(Recommendations);
