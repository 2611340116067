import { useState, memo, useCallback } from 'react';
import { cn } from '@divlab/divanui';

import useMedias from '@Hooks/useMedias';
import Link from '@Navigation/Link';
import Video from '@Promo/elems/Video';
import Img from '@UI/Img';
import Logo from '@Promo/elems/Logo';
import Promocode from '@Promo/elems/Promocode';
import Timer from '@Promo/elems/Timer';
import Sound from '@Promo/elems/Sound';
import Text from '@Promo/elems/Text';
import styles from './PromocodeTemplate.module.css';

import type { FC, MouseEvent } from 'react';
import type { SlideTemplateProps } from '../../typings';

const PromocodeTemplate: FC<SlideTemplateProps> = (props) => {
  const { promotion, banner, currentDate, lazy, ...restProps } = props;
  const { isMobile } = useMedias();
  const [muted, setMuted] = useState(true);

  const needTimer = banner.timerStartDate ? new Date(banner.timerStartDate) < currentDate : null;

  const resources = needTimer ? banner.resources.timer : banner.resources.default;
  const logo = resources.logo;
  const offTimerCondition = banner.offTimerCondition && needTimer;
  const logoPosition = banner.logoPosition || 'default';
  const toggleMute = useCallback((event: MouseEvent) => {
    //для предотвращения клика по ссылке, если иконка находится поверх ссылки
    event.preventDefault();
    event.stopPropagation();
    setMuted((prev) => !prev);
  }, []);
  const stopLink = useCallback((event: MouseEvent) => {
    event.stopPropagation();
  }, []);

  const isVideo = !!resources.desktopVideo;
  const alignmentX = banner.alignmentX;
  const alignmentY = needTimer ? 'top' : banner.alignmentY;
  const text = banner.text || {};

  const inversed =
    isMobile && typeof banner.inversedMobile === 'boolean'
      ? banner.inversedMobile
      : banner.inversed;

  return (
    <Link
      {...restProps}
      className={cn(styles.banner, { [styles.inversed]: inversed })}
      to={banner.link}
    >
      {isVideo ? (
        <Video
          className={styles.video}
          src={isMobile ? resources.mobileVideo : resources.desktopVideo}
          poster={isMobile ? resources.mobileImage : resources.desktopImage}
          muted={muted}
          playsInline
          autoPlay
          loop
        />
      ) : (
        <Img
          className={styles.image}
          src={isMobile ? resources.mobileImage : resources.desktopImage}
          loading={lazy ? 'lazy' : 'eager'}
        />
      )}

      <div
        className={cn(styles.content, {
          [styles.centerCenter]: alignmentX === 'center' && alignmentY === 'center',
          [styles.centerTop]: alignmentX === 'center' && alignmentY === 'top',
          [styles.leftCenter]: alignmentX === 'left' && alignmentY === 'center',
          [styles.leftTop]: alignmentX === 'left' && alignmentY === 'top',
        })}
      >
        {text.period && (
          <Text
            className={styles.period}
            desktop={text.period}
            mobile={text.periodMobile}
            isMobile={isMobile}
          />
        )}

        {logo && logoPosition === 'default' && <Logo className={styles.logo} src={logo} size='M' />}

        <Text
          className={styles.title}
          desktop={text.title}
          mobile={text.titleMobile}
          isMobile={isMobile}
        />

        {text.subtitle && (
          <Text
            className={styles.subtitle}
            desktop={text.subtitle}
            mobile={text.subtitleMobile}
            isMobile={isMobile}
          />
        )}

        {text.promocode && <Promocode className={styles.promocode} text={text.promocode} />}

        <Text
          className={cn(styles.link, { [styles.withSound]: banner.needSound })}
          desktop={text.link}
        />

        {banner.needSound && <Sound className={styles.sound} muted={muted} onClick={toggleMute} />}

        {needTimer && (
          <Timer
            className={styles.timer}
            endDate={new Date(promotion.endDate)}
            textBeforeTimer={text.timer}
            textBeforeTimerMobile={text.timerMobile}
            textAfterTimer={text.afterTimer}
            isMobile={isMobile}
          />
        )}

        {logo && logoPosition === 'bottom' && (
          <Logo className={styles.bottomLogo} src={logo} size='L' />
        )}

        {text.condition && (
          <object onClick={stopLink}>
            <Text
              className={styles.condition}
              desktop={text.condition}
              mobile={offTimerCondition ? '' : text.conditionMobile}
              isMobile={isMobile}
            />
          </object>
        )}
      </div>
    </Link>
  );
};

export default memo(PromocodeTemplate);
