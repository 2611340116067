import { Fragment, memo, useRef } from 'react';
import { cn } from '@divlab/divanui';

import InteriorImage from '@Components/InteriorImage';
import ExpressDesignBanner from '@Components/ExpressDesignBanner';
import styles from './IdeasGallery.module.css';

import type { HomeIdeaData } from '@Types/Ideas';
import type { FC } from 'react';

export interface IdeasGalleryProps {
  className?: string;
  title: string;
  items: HomeIdeaData[];
  collapsed?: boolean;
  positionOfBanner?: number;
}

const IdeasGallery: FC<IdeasGalleryProps> = (props) => {
  const { className, title, items, collapsed, positionOfBanner } = props;
  const refGrid = useRef<HTMLDivElement>(null);
  const analyticsData = { label: 'Листинг', listTitle: title, orderType: 'Листинг' };

  return (
    <div className={cn(styles.grid, { [styles.collapsed]: collapsed }, className)} ref={refGrid}>
      {items.map((img, ind) => (
        <Fragment key={img.src}>
          <div className={styles.imageItem}>
            <InteriorImage
              animated={false}
              className={styles.interiorImage}
              img={img}
              refGrid={refGrid}
              analyticsData={analyticsData}
            />
          </div>
          {ind === positionOfBanner && (
            <div className={styles.imageItem}>
              <ExpressDesignBanner />
            </div>
          )}
        </Fragment>
      ))}
    </div>
  );
};

export default memo(IdeasGallery);
