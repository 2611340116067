import { useMemo } from 'react';
import { Gallery } from '@divlab/divanui';

import Header from './elements/Header';
import Slide from './elements/Slide';
import styles from './InStock.module.css';

import type { FC, HTMLAttributes } from 'react';
import type { CategoriesInStockData } from '@Pages/PageIndex/typings';

interface InStockProps extends HTMLAttributes<HTMLDivElement> {
  data: CategoriesInStockData[];
  mainLink?: string;
}

function splitArray<T>(arr: T[]): T[][] {
  const result: T[][] = [];
  let i = 0;

  while (i < arr.length) {
    if (result.length % 2 === 0) {
      result.push(arr.slice(i, i + 2));
      i += 2;
    } else {
      result.push(arr.slice(i, i + 1));
      i += 1;
    }
  }

  return result;
}

const InStock: FC<InStockProps> = (props) => {
  const { data, mainLink, ...restProps } = props;

  const slides = useMemo(() => splitArray(data), [data]);

  return (
    <div {...restProps} className={styles.container}>
      <Header className={styles.header} mainLink={mainLink} />

      <Gallery cnSlide={styles.slide} scrollbar>
        {slides.map((slide, index) => (
          <Slide key={index} slide={slide} />
        ))}
      </Gallery>
    </div>
  );
};

export default InStock;
