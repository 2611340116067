import { memo } from 'react';

import ProductsSection from '@Components/ProductsSection';
import SquareProductCard from '@Components/SquareProductCard';
import styles from './ProductsGallery.module.css';

import type { HTMLAttributes, FC } from 'react';
import type { ProductData } from '@Types/Product';

interface ProductsGalleryProps extends HTMLAttributes<HTMLDivElement> {
  products: ProductData[];
  analyticsTitle: string;
}

const ProductsGallery: FC<ProductsGalleryProps> = (props) => {
  const { products, analyticsTitle, ...restProps } = props;

  return (
    <ProductsSection
      cnSlide={styles.slide}
      products={products}
      renderProduct={(product) => <SquareProductCard product={product} />}
      analyticsTitle={analyticsTitle}
      {...restProps}
    />
  );
};

export default memo(ProductsGallery);
