import { useQuery } from '@tanstack/react-query';

import * as ApiPromo from '@Api/Promo';
import { useDeps } from '@Contexts/DI';
import { useRequest } from '@Contexts/Request';
import { useDevPanel } from '@Stores/DevPanel';

import type { UseQueryResult } from '@tanstack/react-query';
import type {
  MainBannerData,
  CardBannerData,
  CategoryBannerData,
  Promotion,
  BannerData,
} from '@Promo/typings';

export interface Params<T = string> {
  listName?: string;
  type: T;
}

function usePromotions(params: Params<'main'>): UseQueryResult<Promotion<MainBannerData>[]>;
function usePromotions(params: Params<'card'>): UseQueryResult<Promotion<CardBannerData>[]>;
function usePromotions(params: Params<'category'>): UseQueryResult<Promotion<CategoryBannerData>[]>;
function usePromotions(params: Params): UseQueryResult<Promotion<BannerData>[]> {
  const { logger } = useDeps();
  const { listName, type } = params;
  const { language, region, country } = useRequest();
  const { currentDate: devCurrentDate } = useDevPanel();
  const currentDate = devCurrentDate || new Date();
  const timestamp = currentDate?.getTime();

  const keys = ['list-promotions', { type, listName, country, lang: language.id, region }];

  const result = useQuery({
    queryKey: keys,
    queryFn: async () => {
      try {
        const inputParams = {
          type,
          listName,
          country,
          lang: language.id,
          region,
          currentDate: timestamp,
        };
        const promotions = await ApiPromo.getList(inputParams);

        return promotions;
      } catch (err) {
        // Этот запрос мы считаем не критичным, если произошла ошибка,
        // то допустимо отдать пустой массив данных, чтобы сохранить доступность страницы
        logger.log(err);
        return Promise.resolve([]);
      }
    },
    enabled: true,
    placeholderData: (previousData) => previousData,
  });

  return result;
}

export default usePromotions;
