import { cn, Price } from '@divlab/divanui';

import { useRequest } from '@Contexts/Request';
import Img from '@UI/Img';
import Link from '@Navigation/Link';
import useTranslation from '@Queries/useTranslation';
import styles from './PopularCard.module.css';

import type { PopularCategoryData } from '@Components/Popular/typings';
import type { LinkProps } from '@Navigation/Link';
import type { FC } from 'react';

export interface PopularCardProps extends Omit<LinkProps, 'to'> {
  className?: string;
  category: PopularCategoryData;
  cardFit?: 'big' | 'small';
}

const PopularCard: FC<PopularCardProps> = (props) => {
  const { className, category, cardFit = 'big', ...restProps } = props;
  const { t } = useTranslation();
  const model = t('ui.plural-models', category.count);
  const { country } = useRequest();
  const isUZB = country === 'UZB';

  return (
    <Link {...restProps} to={category.url} className={cn(styles.wrapper, className)}>
      <Img
        className={styles.imgWrapper}
        cnImage={styles.image}
        src={category.image}
        alt={category.alt}
      />
      <div className={styles.titleWrap}>
        <span className={styles.title}>{category.name}</span>
        {category.count > 0 && <span className={styles.count}>{`${category.count} ${model}`}</span>}
      </div>
      {category.minPrice > 0 && (
        <div
          className={cn(styles.discount, {
            [styles.big]: cardFit === 'big',
            [styles.small]: cardFit === 'small',
            [styles.longPrice]: isUZB,
          })}
        >
          {`${t('ui.range.from')} `}
          <Price price={category.minPrice} />
        </div>
      )}
    </Link>
  );
};

export default PopularCard;
