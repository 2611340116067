import * as ModalWindows from '@Stores/ModalWindows';
import * as Meta from '@Queries/Meta';
import { useRequest } from '@Contexts/Request';
import useSEOCategories from '@Queries/useSEOCategories';
import usePage from '@Queries/usePage';
import useTranslation from '@Queries/useTranslation';
import usePromotions from '@Queries/usePromotions';
import usePromoPopup from '@Promo/hooks/usePromoPopup';
import LayoutMain from '@Layouts/LayoutMain';
import Page from '@Components/Page';
import Suspense from '@Components/Suspense';
import InstagramSection from '@Components/InstagramSection';
import Popular from '@Components/Popular';
import ShowroomsMap from '@Components/ShowroomsMap';
import Recommendations from './elems/Recommendations';
import MainGallery from './elems/MainGallery';
import Ideas from './elems/Ideas';
import Categories from './elems/Categories';
import FindYourOwn from './elems/FindYourOwn';
import CategoriesInStockBlock from './elems/CategoriesInStockBlock';
import MainDescription from './elems/MainDescription';
import ProductsSection from '@Components/ProductsSection';
import styles from './PageIndex.module.css';

import type { FC } from 'react';
import type { PageIndexData } from './typings';

const PageIndex: FC = () => {
  const page = usePage<PageIndexData>();
  const {
    popularCategories,
    categoriesInStock,
    extraDiscounts,
    bestsellers,
    novelties,
    text,
    homeIdeas,
    categoryPopularTop,
    interiorBlock,
  } = page.data;
  const { t } = useTranslation();
  const { data: meta } = Meta.useMeta();
  const { isBlr } = useRequest();
  const isMoscow = meta.region.id === 1;
  const regions = Meta.useAllRegions({ enabled: isMoscow });
  const popup = usePromoPopup({ slug: '' });
  const instagram = page.data.instagram;
  const { data: seoCategories } = useSEOCategories({ enabled: isMoscow });
  const { data: mainPromotions } = usePromotions({ listName: 'main', type: 'main' });
  const { data: cardPromotions } = usePromotions({ listName: 'recommendations', type: 'card' });

  ModalWindows.useSSRModals(
    [{ id: 'AllRegions', data: { regions: regions.data } }],
    !!regions.data,
  );

  ModalWindows.useSSRModals(
    [
      { id: 'SEOSofasCategory', data: { seoCategories: seoCategories?.sofas } },
      { id: 'SEOClosetsCategory', data: { seoCategories: seoCategories?.closets } },
      { id: 'SEOBedsCategory', data: { seoCategories: seoCategories?.beds } },
    ],
    !!seoCategories,
  );

  const hasExtraDiscounts = extraDiscounts?.items?.length > 0;
  const hasBestsellers = bestsellers?.items?.length > 0;
  const hasCategoryPopularTop = categoryPopularTop?.length > 0;
  const hasCardPromotions = cardPromotions?.length > 0;
  const hasNovelties = novelties?.items?.length > 0;
  const hasPopularCategories = popularCategories?.length > 0;
  const hasHomeIdeas = homeIdeas?.length > 0;
  const map = page.data.map;
  const hasInstagram = instagram?.items?.length > 0;
  const hasDescription = !!text?.text;

  return (
    <LayoutMain>
      <Page>
        <div className={styles.page} data-testid='page-index'>
          <MainGallery promotions={mainPromotions} />

          <Suspense fallback={null}>{popup}</Suspense>

          {hasCategoryPopularTop && <Categories categories={categoryPopularTop} />}

          {hasExtraDiscounts && (
            <ProductsSection
              title={extraDiscounts.header}
              description={extraDiscounts.text}
              products={extraDiscounts.items}
              allTagsBottom
            />
          )}

          {hasBestsellers && !hasExtraDiscounts && (
            <ProductsSection
              title={bestsellers.header}
              description={bestsellers.text}
              products={bestsellers.items}
              titleTag='h1'
              data-testid='hits'
            />
          )}

          {hasCardPromotions && <Recommendations promotions={cardPromotions} />}

          {hasPopularCategories && (
            <Popular title={t('popular-categories')} categories={popularCategories} />
          )}

          {categoriesInStock && <CategoriesInStockBlock data={categoriesInStock} />}

          {interiorBlock && <FindYourOwn data={interiorBlock} />}

          {hasBestsellers && hasExtraDiscounts && (
            <ProductsSection
              title={bestsellers.header}
              description={bestsellers.text}
              products={bestsellers.items}
              titleTag='h1'
              data-testid='hits'
            />
          )}

          {hasHomeIdeas && <Ideas title={t('home-ideas')} ideas={homeIdeas} />}

          {hasNovelties && (
            <ProductsSection
              title={novelties.header}
              description={novelties.text}
              products={novelties.items}
              titleTag={isBlr ? 'div' : 'h2'}
            />
          )}

          {map && <ShowroomsMap className={styles.container} map={map} />}

          {hasInstagram && (
            <InstagramSection
              title={instagram.headerText}
              posts={instagram.items}
              titleTag={isBlr ? 'div' : 'h2'}
            />
          )}

          {hasDescription && (
            <MainDescription
              text={text}
              regions={regions.data}
              seoCategories={seoCategories}
              className={styles.container}
            />
          )}
        </div>
      </Page>
    </LayoutMain>
  );
};

export default PageIndex;
